<template>
  <div class="dashboard">
    <moon-nav></moon-nav>
    <main class="main">
      <h1 v-if="hasTitle">{{ title }}</h1>
      <div class="main-content" :class="{ 'main-content-has-title': hasTitle }">
        <router-view />
      </div>
    </main>
  </div>
</template>
<script lang="ts" setup>
import MoonNav from "@/components/nav/MoonNav.vue";

import { ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const hasTitle = ref<boolean>(false);
const title = ref<string | unknown>("");

watch(
  () => route.path,
  (newPath, oldPath) => {
    if (newPath !== oldPath) {
      const routeWithMeta = route.matched[route.matched.length - 1];
      if (routeWithMeta && typeof routeWithMeta.meta.title === "string") {
        hasTitle.value = true;
        title.value = route.meta.title;
      } else {
        hasTitle.value = false;
      }
    }
  },
  { immediate: true, deep: true },
);
</script>
<style>
@import "@/assets/styles/auth.css";
@import "vue-multiselect/dist/vue-multiselect.css";

.main-content-has-title {
  display: grid;
  place-content: center;
}

@media only screen and (max-width: 37.5rem) {
  h1 {
    padding: 0;
  }
  .main-content-has-title {
    place-content: flex-start;
  }
}

.view-dashboard-route {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.circular-border {
  box-sizing: border-box;
  border-radius: 50%;
  outline: var(--color-border-secondary) var(--dimension-avatar-border-width)
    solid;
  box-shadow: 0 0.3rem 0.6rem var(--color-shadow);
}

.router-link-active .circular-border {
  outline-color: var(--color-background);
}

.user-avatar-small .circular-border {
  outline-width: var(--dimension-avatar-border-width-small);
}

.moon-card {
  width: 100%;
  height: 100%;
  background-color: var(--color-background);
  border-radius: 0.5rem;
  box-sizing: border-box;
  padding: 1.2rem 2.8rem 2.8rem 2.8rem;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.moon-card-title {
  align-self: center;
}

.moon-card-title-small {
  align-self: center;
  color: var(--color-text-hint);
  font-size: 0.8em;
}

.invert {
  filter: invert(100%);
}

.icon img {
  width: 100%;
  height: 100%;
}

.modal-actions {
  width: 100%;
  padding: 0 3.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.login-card {
  background-color: var(--color-background);
  width: 48rem;
  min-height: 32rem;
  border-radius: 1.2rem;
  box-shadow: 0 0.3rem 0.5rem rgb(0 0 0 / 15%);
}
@media only screen and (max-width: 37rem) {
  .login-card {
    width: 100%;
    min-height: unset;
    box-shadow: none;
    align-items: flex-start;
    justify-self: flex-start;
  }
}
</style>
<style scoped>
.dashboard {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow-y: scroll;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-background-soft);
  overflow-x: scroll;
}

h1 {
  display: flex;
  place-self: start;
  margin-top: 5.6rem;
  margin-left: 3.2rem;
}

.main-content {
  width: 100%;
  height: 100%;
  padding: 0 3.2rem 3.2rem;
  max-width: 120rem;
}

@media only screen and (max-width: 62rem) {
  .dashboard {
    flex-direction: column;
  }
  h1 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 37.5rem) {
  .main-content {
    margin: 0.4rem;
  }

  h1 {
    font-size: 2.4rem;
    margin: 0 0 1.6rem 0.8rem;
  }
}
</style>
