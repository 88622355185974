<template>
  <router-link
    v-if="showRoute"
    v-slot="{ isActive }"
    :to="{ name: routeName }"
    class="nav-item-link"
    :exact="routeName === 'dashboard'"
  >
    <li
      :class="{
        'is-active':
          !disableActiveStyling && isActive && routeName !== 'dashboard',
        'moonboarding-active': moonboardingActive,
      }"
      class="nav-item"
      @click="onClick"
    >
      <slot name="icon"></slot>
      <slot name="text"></slot>
      <slot name="button"></slot>
    </li>
  </router-link>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import type { RouteName } from "@/router/routeName";
import routeNameToMeta from "@/router/routeNameToMeta";
import useUserPermissions from "@/functional/hooks/useUserPermissions";

const props = withDefaults(
  defineProps<{
    routeName: RouteName;
    disableActiveStyling?: boolean;
    moonboardingActive?: boolean;
  }>(),
  {
    moonboardingActive: false,
  },
);

const { hasPermissions } = useUserPermissions();

const emit = defineEmits(["click"]);

const showRoute = computed(() => {
  const requiredPermissions = routeNameToMeta(
    props.routeName,
  ).requiredPermissions;

  return hasPermissions(requiredPermissions);
});

const onClick = (e: Event) => {
  emit("click", e);
};
</script>
<style scoped>
.nav-item {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  white-space: nowrap;
  padding: 1.5rem 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  stroke: var(--vt-c-purple-5);
}

.nav-item:hover {
  border-radius: 0.25rem;
  background: rgba(234, 235, 253, 0.2);
  color: var(--vt-c-purple-5);
  stroke: var(--vt-c-purple-5);
}

.is-active {
  background: var(--vt-c-purple-5);
  color: var(--vt-c-purple-4);
  stroke: var(--vt-c-purple-4);
}
</style>
