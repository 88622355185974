<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="1.5 -1 24 24"
    fill="none"
  >
    <path
      d="M8.57372 11.3512L5.50309 5.90441C5.15002 5.27812 4.97348 4.96497 5.00323 4.70858C5.02919 4.48489 5.14904 4.2824 5.33337 4.15082C5.54464 4 5.90653 4 6.63031 4H8.43393C8.70291 4 8.83741 4 8.95804 4.03851C9.06479 4.07258 9.1632 4.12833 9.247 4.20221C9.3417 4.2857 9.40997 4.40056 9.5465 4.6303L12.5 9.6L15.4535 4.6303C15.59 4.40056 15.6583 4.2857 15.753 4.20221C15.8368 4.12833 15.9352 4.07258 16.042 4.03851C16.1626 4 16.2971 4 16.5661 4H18.3697C19.0935 4 19.4554 4 19.6666 4.15082C19.851 4.2824 19.9708 4.48489 19.9968 4.70858C20.0265 4.96497 19.85 5.27812 19.4969 5.90441L16.4263 11.3512M11.2894 13.6L12.5 12.8V16.8M11.4912 16.8H13.5088M16.2094 11.123C18.258 13.1538 18.258 16.4462 16.2094 18.477C14.1607 20.5077 10.8393 20.5077 8.79063 18.477C6.742 16.4462 6.742 13.1538 8.79063 11.123C10.8392 9.09232 14.1607 9.09232 16.2094 11.123Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
