<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M6 15L1 10M1 10L6 5M1 10H13M10 15C10 15.93 10 16.395 10.1022 16.7765C10.3796 17.8117 11.1883 18.6204 12.2235 18.8978C12.605 19 13.07 19 14 19H14.5C15.8978 19 16.5967 19 17.1481 18.7716C17.8831 18.4672 18.4672 17.8831 18.7716 17.1481C19 16.5967 19 15.8978 19 14.5V5.5C19 4.10217 19 3.40326 18.7716 2.85195C18.4672 2.11687 17.8831 1.53284 17.148 1.22836C16.5967 1 15.8978 1 14.5 1H14C13.07 1 12.605 1 12.2235 1.10222C11.1883 1.37962 10.3796 2.18827 10.1022 3.22354C10 3.60504 10 4.07003 10 5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
