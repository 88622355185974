<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8.4 8.11111H10.6M8.4 11.2222H10.6M8.4 14.3333H10.6M13.4 8.11111H15.6M13.4 11.2222H15.6M13.4 14.3333H15.6M18.4 19V7.48889C18.4 6.6177 18.4 6.1821 18.2256 5.84935C18.0722 5.55665 17.8274 5.31868 17.5264 5.16955C17.1841 5 16.7361 5 15.84 5H8.16C7.26392 5 6.81587 5 6.47362 5.16955C6.17256 5.31868 5.92779 5.55665 5.77439 5.84935C5.6 6.1821 5.6 6.6177 5.6 7.48889V19M20 19H4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
