<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.2 12.7364C18.0254 13.3211 20 14.6566 20 16.2105C20 18.3034 16.4183 20 12 20C7.58172 20 4 18.3034 4 16.2105C4 14.6566 5.97455 13.3211 8.8 12.7364M12 15.7895V4L16.2542 6.75574C16.5645 6.95675 16.7196 7.05726 16.7691 7.18388C16.8123 7.29432 16.8089 7.41886 16.7597 7.5265C16.7034 7.6499 16.543 7.74082 16.2222 7.92265L12 10.3158"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
