<template>
  <nav class="nav">
    <div class="moon-nav-top">
      <ul class="moon-nav-first">
        <div class="moon-logo-container">
          <icon-white-moon class="moon-icon" />
        </div>
      </ul>
      <ul class="moon-nav-middle">
        <moon-nav-item :route-name="RN.ORGANISATIONS">
          <template #icon>
            <icon-organisations />
          </template>
          <template #text>
            <span class="nav-text">Organisations</span>
          </template>
        </moon-nav-item>
        <moon-nav-item
          :route-name="RN.USERS"
          :moonboarding-active="urlHash == SlideHash.Learners"
        >
          <template #icon>
            <icon-people />
          </template>
          <template #text>
            <span class="nav-text">Learners</span>
          </template>
        </moon-nav-item>
        <moon-nav-item
          :route-name="RN.LEADERBOARD"
          :moonboarding-active="urlHash == SlideHash.Leaderboard"
        >
          <template #icon>
            <icon-leaderboard />
          </template>
          <template #text>
            <span class="nav-text">Leaderboard</span>
          </template>
        </moon-nav-item>
        <moon-nav-item
          :route-name="RN.MY_LEARNING"
          :moonboarding-active="urlHash == SlideHash.MyLearning"
        >
          <template #icon>
            <icon-my-learning />
          </template>
          <template #text>
            <span class="nav-text">My Learning</span>
          </template>
        </moon-nav-item>
        <moon-nav-item
          :route-name="RN.TRENDS_ANALYTICS"
          :moonboarding-active="urlHash == SlideHash.TrendsAndAnalytics"
        >
          <template #icon>
            <icon-analytics />
          </template>
          <template #text>
            <span class="nav-text">Analytics</span>
          </template>
        </moon-nav-item>
        <moon-nav-item :route-name="RN.PLATFORM_ISSUES">
          <template #icon>
            <icon-warning />
          </template>
          <template #text>
            <span class="nav-text">Platform Issues</span>
          </template>
        </moon-nav-item>
        <moon-nav-item
          :route-name="RN.MY_PROFILE"
          :moonboarding-active="urlHash == SlideHash.MyProfile"
        >
          <template #icon>
            <icon-profile />
          </template>
          <template #text>
            <span class="nav-text">My Profile</span>
          </template>
        </moon-nav-item>
      </ul>
    </div>
    <div class="moon-nav-bottom">
      <router-link v-slot="{ isActive }" :to="RN.REPORT_GENERATOR">
        <button
          v-if="showReportBtn"
          class="generate-report-btn"
          :class="{
            'report-active-btn': isActive,
          }"
          @click="onGenerateReportClicked"
        >
          Generate Report
        </button>
      </router-link>
    </div>
    <div class="moon-nav-bottom">
      <ul>
        <moon-nav-item :route-name="RN.MOONBOARDING">
          <template #icon>
            <icon-moonboarding />
          </template>
          <template #text>
            <span class="nav-text">Moonboarding</span>
          </template>
        </moon-nav-item>
        <moon-nav-item :route-name="RN.LOGIN" @click="onLogout">
          <template #icon>
            <icon-logout />
          </template>
          <template #text>
            <span class="nav-text">Logout</span>
          </template>
        </moon-nav-item>
      </ul>
    </div>
  </nav>
  <nav class="nav-hamburger">
    <div class="navbar">
      <div class="container nav-container">
        <input class="checkbox" type="checkbox" @click="toggleMenu" />
        <div class="hamburger-lines">
          <span
            class="line line1"
            :class="{
              'line1-open': isNavMenuOpen,
            }"
          ></span>
          <span
            class="line line2"
            :class="{
              'line2-open': isNavMenuOpen,
            }"
          ></span>
          <span
            class="line line3"
            :class="{
              'line3-open': isNavMenuOpen,
            }"
          ></span>
        </div>
        <icon-black-moon class="brand-icon" />
        <div
          class="menu-items"
          :class="{ 'menu-items-open': isNavMenuOpen }"
          @click="toggleMenu"
        >
          <moon-nav-item :route-name="RN.ORGANISATIONS">
            <template #icon>
              <icon-organisations class="icon" />
            </template>
            <template #text>
              <div class="nav-text-container">
                <div class="nav-text">Organisations</div>
                <icon-chevron-right />
              </div>
            </template>
          </moon-nav-item>
          <moon-nav-item :route-name="RN.USERS">
            <template #icon>
              <icon-learners class="icon" />
            </template>
            <template #text>
              <div class="nav-text-container">
                <div class="nav-text">Learners</div>
                <icon-chevron-right />
              </div>
            </template>
          </moon-nav-item>
          <moon-nav-item
            :route-name="RN.MY_LEARNING"
            :moonboarding-active="urlHash == SlideHash.MyLearning"
          >
            <template #icon>
              <icon-my-learning class="nav-icon" />
            </template>
            <template #text>
              <div class="nav-text-container">
                <div class="nav-text">My Learning</div>
                <icon-chevron-right />
              </div>
            </template>
          </moon-nav-item>
          <moon-nav-item :route-name="RN.REPORT_GENERATOR">
            <template #icon>
              <icon-report class="nav-icon" />
            </template>
            <template #text>
              <div class="nav-text-container">
                <span class="nav-text">Generate Report</span>
                <icon-chevron-right />
              </div>
            </template>
          </moon-nav-item>
          <moon-nav-item :route-name="RN.MY_PROFILE">
            <template #icon>
              <icon-profile class="icon" />
            </template>
            <template #text>
              <span class="nav-text-container">
                <span class="nav-text">My Profile</span>
                <icon-chevron-right />
              </span>
            </template>
          </moon-nav-item>
          <moon-nav-item :route-name="RN.LOGIN" @click="onLogout">
            <template #icon>
              <icon-logout />
            </template>
            <template #text>
              <div class="nav-text-container">
                <span class="nav-text">Logout</span>
                <icon-chevron-right />
              </div>
            </template>
          </moon-nav-item>
        </div>
      </div>
    </div>
  </nav>
</template>
<script lang="ts" setup>
import MoonNavItem from "@/components/nav/MoonNavItem.vue";
import { useAuthStore } from "@/stores/useAuthStore";
import { useRoute, useRouter } from "vue-router";
import { computed, ref, watch } from "vue";
import { RouteName } from "@/router/routeName";
import IconLogout from "@/components/icons/nav/IconLogout.vue";
import IconLearners from "@/components/icons/nav/IconLearners.vue";
import IconOrganisations from "@/components/icons/nav/IconOrganisations.vue";
import IconProfile from "../icons/IconProfile.vue";
import useUserPermissions from "@/functional/hooks/useUserPermissions";
import routeNameToMeta from "@/router/routeNameToMeta";
import IconWhiteMoon from "@/components/icons/nav/IconWhiteMoon.vue";
import IconChevronRight from "../icons/IconChevronRight.vue";
import IconReport from "../icons/nav/IconReport.vue";
import IconAnalytics from "../icons/IconAnalytics.vue";
import IconMyLearning from "../icons/nav/IconMyLearning.vue";
import IconLeaderboard from "../icons/IconLeaderboard.vue";
import IconMoonboarding from "../icons/IconMoonboarding.vue";
import IconWarning from "../icons/IconWarning.vue";
import IconPeople from "../icons/IconPeople.vue";
import { SlideHash } from "@/functional/moonboardingUrlHashes";
const userStore = useAuthStore();
const router = useRouter();
const RN = RouteName;
const isNavMenuOpen = ref<boolean>(false);

const route = useRoute();
const urlHash = ref<string>(route.hash.replace("#", ""));

watch(
  () => route.hash,
  (newHash) => {
    urlHash.value = newHash.replace("#", "");
  },
);
const onLogout = () => {
  console.log("Logging out");
  userStore.logout(router);
};
const { hasPermissions } = useUserPermissions();
const showReportBtn = computed(() => {
  const requiredPermissions = routeNameToMeta(
    RouteName.REPORT_GENERATOR,
  ).requiredPermissions;

  return hasPermissions(requiredPermissions);
});

const onGenerateReportClicked = () => {
  router.push({
    name: RouteName.REPORT_GENERATOR,
  });
};

const toggleMenu = () => {
  isNavMenuOpen.value = !isNavMenuOpen.value;
};
</script>
<style>
.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--vt-c-purple-4);
  transition: all 0.8s ease;
  height: auto;
  overflow-y: auto;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  height: 100%;
  color: var(--vt-c-white);
  font-weight: 800;
  line-height: 2rem;
  gap: 1rem;
  width: 13.8;
}

.moon-nav-top {
  padding: 0 1rem;
}
.moon-nav-bottom {
  padding: 0 1rem;
}
.generate-report {
  padding: 2rem 3.2rem;
}

.generate-report-btn {
  width: 100%;
  flex-shrink: 0;
  padding: 1.25rem 2rem;
  border-radius: 0.4rem;
}

.nav-item svg {
  height: auto;
  width: 3rem;
  flex-shrink: 0;
}

.moon-logo-container {
  height: 10rem;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding-left: 1rem;
}

.moon-icon {
  width: 4rem;
}

.generate-report-btn {
  font-weight: bold;
  font-size: 1.5rem;
}
.generate-report-btn:hover {
  border: 1px solid var(--vt-c-purple-5);
  background: var(--vt-c-purple-4);
  color: var(--vt-c-purple-5);
}
.report-active-btn {
  background-color: var(--vt-c-white);
  background: var(--vt-c-purple-5);
  color: var(--vt-c-purple-4);
}
@media only screen and (max-width: 90rem) {
  .nav {
    padding: 0;
  }
  .moon-nav-top {
    gap: 0;
  }
  .moon-nav-bottom {
    gap: 0;
  }
}

.nav-hamburger {
  display: none;
}
.nav-text {
  font-weight: 700;
}

@media only screen and (max-width: 62rem) {
  .nav {
    display: none;
  }

  .nav-hamburger {
    display: block;
    z-index: 1000;
    background-color: var(--color-background-soft);
  }

  .nav-text-container {
    width: 100%;
    justify-content: space-between;
  }

  .nav-item-link {
    width: 100%;
  }

  .nav-item-link .nav-text-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container {
    width: 1050px;
    width: 100%;
    margin: auto;
  }

  .navbar {
    width: 100vw;
    height: 6rem;
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
  }

  .navbar .nav-container li {
    list-style: none;
  }

  .navbar .nav-container a {
    text-decoration: none;
    color: #0e2431;
    font-weight: 500;
  }

  .navbar .nav-container a:hover {
    font-weight: bolder;
  }

  .nav-container {
    display: block;
    position: relative;
    height: 60px;
  }

  .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 20px;
    right: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .nav-container .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 17px;
    right: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #0e2431;
  }

  .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  .navbar .menu-items {
    padding-top: 6rem;
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.5s ease-in-out;
    background-color: white;
    overflow: hidden;
    justify-content: start;
  }

  .menu-items .nav-item-link {
    width: 100%;
    height: 6rem;
  }

  .logo {
    position: absolute;
    top: -2.2rem;
    right: 15px;
    /* font-size: 1.5rem; */
    font-weight: bold;
    color: #0e2431;
  }

  .nav-container .menu-items-open {
    transform: translateX(0);
  }

  .nav-container .line1-open {
    transform: rotate(45deg);
  }
  .nav-container .line2-open {
    transform: scaleY(0);
  }

  .nav-container .line3-open {
    transform: rotate(-45deg);
  }

  .nav-container .logo-open {
    display: none;
  }

  .nav-hamburger .brand-icon {
    position: absolute;
    z-index: 1000;
    width: 3.5rem;
    height: 3.5rem;
    top: 1.2rem;
    left: 0;
    right: 0;
    margin: auto;
  }
}
</style>

<style scoped>
ul {
  box-sizing: border-box;
}
</style>
