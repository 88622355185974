<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M7 5H2.6C2.03995 5 1.75992 5 1.54601 5.10899C1.35785 5.20487 1.20487 5.35785 1.10899 5.54601C1 5.75992 1 6.03995 1 6.6V17.4C1 17.9601 1 18.2401 1.10899 18.454C1.20487 18.6422 1.35785 18.7951 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H7M7 19H13M7 19L7 2.6C7 2.03995 7 1.75992 7.10899 1.54601C7.20487 1.35785 7.35785 1.20487 7.54601 1.10899C7.75992 1 8.03995 1 8.6 1L11.4 1C11.9601 1 12.2401 1 12.454 1.10899C12.6422 1.20487 12.7951 1.35785 12.891 1.54601C13 1.75992 13 2.03995 13 2.6V19M13 9H17.4C17.9601 9 18.2401 9 18.454 9.10899C18.6422 9.20487 18.7951 9.35785 18.891 9.54601C19 9.75992 19 10.0399 19 10.6V17.4C19 17.9601 19 18.2401 18.891 18.454C18.7951 18.6422 18.6422 18.7951 18.454 18.891C18.2401 19 17.9601 19 17.4 19H13"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
