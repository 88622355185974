<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="1 2 22 22"
    fill="none"
  >
    <path
      d="M12 10.0576V13.168M12 16.2784H12.0078M10.9207 6.08542L4.50966 17.1325C4.15406 17.7452 3.97627 18.0516 4.00254 18.303C4.02547 18.5224 4.14065 18.7217 4.31942 18.8513C4.52439 19 4.87925 19 5.58898 19H18.411C19.1207 19 19.4756 19 19.6806 18.8513C19.8594 18.7217 19.9745 18.5224 19.9975 18.303C20.0237 18.0516 19.8459 17.7452 19.4903 17.1325L13.0793 6.08542C12.725 5.47488 12.5478 5.1696 12.3167 5.06708C12.1151 4.97764 11.8849 4.97764 11.6833 5.06708C11.4522 5.1696 11.275 5.47488 10.9207 6.08542Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
